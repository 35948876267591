import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"


export default function Default({ location, data }) {
  let page_heading = data.document.frontmatter.title
  if (data.document.headings.length > 0) {
    if (data.document.headings[0].depth === 1 && page_heading === undefined) {
      page_heading = data.document.headings[0].value
    }
  }
  return (
    <Layout location={location} title={page_heading}>
      <div className={"page-" + data.document.fields.slug.slice(1, data.document.fields.slug.length).replace("/", "-")}>
        <div dangerouslySetInnerHTML={{ __html: data.document.html }} />
      </div>
    </Layout>
  )
}


export const query = graphql`
  query($slug: String!) {
    document: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      fields {
        slug
      }
      headings {
        depth
        value
      }
    }
  }
`
